@import url('https://fonts.googleapis.com/css?family=Pacifico|Poppins:400,700|Roboto&display=swap');
* {
  box-sizing: border-box;
}
hr{
  padding: 0;
  margin-top: 0;
  margin-bottom: 3px;
}
p, .section-box{
  font-size: 14px;
  border-left: solid 8px white;
  padding: 10px;
  background-color: rgba($color: #fff, $alpha: 0.8);
  margin-top: 10px;
  margin-bottom: 10px;
}

a {
  color: black;
  text-decoration: none;
}
.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  // background-color: rgb(13, 75, 130);
  // background-image: url('./img/bot-wireframe.jpg');
  // background-size: cover;
  // background-position: center;
  font-family: 'Roboto', monospace;
  position: relative;
  // overflow-y: scroll;
}

// Navbar style
.nav-bar{
  height: 280px;
  // background-color: rgba(255, 255, 255, 0.4);
  margin: 30px;
  * {
    z-index: 2;
    // color: white;
    color: rgb(13, 75, 130);
    text-decoration: none;
  }
  .title {
    height: 250px;
    font-size: 8em;
    // padding-top: 5px;
    font-family: 'Pacifico';
  }
  .nav-button{
    font-family: 'Pacifico';
    font-weight: 700;
    // float: right;
    margin: 30px;
  }
  @media only screen and (max-width: 980px) {
    height: 180px;
    .title{
      font-size: 5em;
      height: 150px;
    }
  }
  @media only screen and (max-width: 670px) {
    height: 120px;
    .title{
      font-size: 3em;
      height: 100px;
    }
  }
}

//Landing Page
.landing-page {
  display: flex;
  margin: 30px;
  width: calc( 100% - 60px );
  font-family: 'Merriweather', sans-serif;
  flex-wrap: wrap;
  justify-content: space-around;
  color: black;
  text-align: left;
  .left-section{
    width: 25%;
  }
  .middle-section{
    width: 45%;
  }
  .right-section{
    width: 25%;
  }
  h1, b {
    font-family: 'Poppins';
    font-weight: '700';
  }
  .section-title{
    font-family: 'Pacifico';
    padding: 20px;
    background-color: rgba(13, 75, 130, 0.8);
    margin-top: 20px;
    color: white;
  }
  @media only screen and (max-width: 670px) {
    .middle-section{
      width: 90%;
      order: 1;
    }
    .left-section{
      width: 40%;
      order: 2;
    }
    .right-section{
      width: 40%;
      order: 3;
    }
  }
}


// Article

.article-img-container {
  position: relative;
  img{
    width: 100%;
  }
  .layer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0;
    border: 0;
  }
}

.article-img-container:hover .layer{
  display: none;
}
.article-img-container:hover h1{
  color: black;
}

.article-time{
  font-size: 9px;
  text-align: end;
  width: 100%;
}


//Footer
footer{
  width:100%;
}