@import url(https://fonts.googleapis.com/css?family=Pacifico|Poppins:400,700|Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{box-sizing:border-box}hr{padding:0;margin-top:0;margin-bottom:3px}p,.section-box{font-size:14px;border-left:solid 8px white;padding:10px;background-color:rgba(255,255,255,0.8);margin-top:10px;margin-bottom:10px}a{color:black;text-decoration:none}.App{text-align:center;width:100%;min-height:100vh;font-family:'Roboto', monospace;position:relative}.nav-bar{height:280px;margin:30px}.nav-bar *{z-index:2;color:#0d4b82;text-decoration:none}.nav-bar .title{height:250px;font-size:8em;font-family:'Pacifico'}.nav-bar .nav-button{font-family:'Pacifico';font-weight:700;margin:30px}@media only screen and (max-width: 980px){.nav-bar{height:180px}.nav-bar .title{font-size:5em;height:150px}}@media only screen and (max-width: 670px){.nav-bar{height:120px}.nav-bar .title{font-size:3em;height:100px}}.landing-page{display:flex;margin:30px;width:calc( 100% - 60px);font-family:'Merriweather', sans-serif;flex-wrap:wrap;justify-content:space-around;color:black;text-align:left}.landing-page .left-section{width:25%}.landing-page .middle-section{width:45%}.landing-page .right-section{width:25%}.landing-page h1,.landing-page b{font-family:'Poppins';font-weight:'700'}.landing-page .section-title{font-family:'Pacifico';padding:20px;background-color:rgba(13,75,130,0.8);margin-top:20px;color:white}@media only screen and (max-width: 670px){.landing-page .middle-section{width:90%;order:1}.landing-page .left-section{width:40%;order:2}.landing-page .right-section{width:40%;order:3}}.article-img-container{position:relative}.article-img-container img{width:100%}.article-img-container .layer{position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(255,255,255,0.3);margin:0;border:0}.article-img-container:hover .layer{display:none}.article-img-container:hover h1{color:black}.article-time{font-size:9px;text-align:end;width:100%}footer{width:100%}

